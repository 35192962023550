$margin-small: 15px;
.boxsizer {
  min-width: 200px;
  min-height: 73px; 
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 1rem !important;
  padding-left: 1rem !important;
}

.iban {
  left: 44px;
  top: 16px;

  @apply 
  text-gray-800
  text-base
  font-normal;
}

.email {
  left: 44px;
  top: 41px;
  font-size: 13px;
  @apply  
  text-neutral-500 
  font-normal;
}
.radioboxcontainer input[type='radio'] {
  display: none;
  + label span {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: $margin-small;
    vertical-align: top;
    background: url(/assets/icons/radio_off.svg) 0px 0 no-repeat;
    background-size: 24px 24px;
    cursor: pointer;
  }
  &:checked + label span {
    background: url(/assets/icons/radio_on.svg) 0px 0px no-repeat;
    background-size: 24px 24px;
  }
  &:disabled + label span {
    background: url(/assets/icons/radio_off.svg) 0px 0px no-repeat;
    background-size: 24px 24px;
  }
}
